import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/world-map.svg";
import { LocationOn, MailRounded, PhoneInTalkRounded } from "@mui/icons-material";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0  md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-contain bg-no-repeat bg-center flex flex-col md:pl-24  md:h-144`,
]);
const TextContent = tw.div`text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-3xl sm:text-4xl lg:text-4xl text-center`;
const Description = tw.p` text-sm md:text-base lg:text-lg font-medium text-center leading-relaxed text-headings-4 md:mb-8`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`
const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`

const Card = tw.div`bg-white max-w-xs mx-auto sm:max-w-none sm:mx-0 shadow-3xl rounded-2xl flex flex-col w-80 p-8 sm:border relative mb-16 items-center`;
const CardText = tw.div`text-xl font-semibold text-headings-3 text-center`;
const CardTitle = tw.h5`text-3xl font-bold hover:text-headings-2 text-center`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const SubmitButton = tw(PrimaryButtonBase)`inline-block lg:ml-6 mt-6 lg:mt-0`

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr/> with us.</>,
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  submitButtonText = "Contact Me",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  return (
    <Container>
        <Heading>We'll love to hear from you</Heading>
        {/* <Description>We love Apps and Coffee Talks.</Description>   */}
          <Image imageSrc={EmailIllustrationSrc}>
            <div css={tw`mt-2 md:mt-32`}>   
        <Card>
            <LocationOn css={tw`w-64 h-64 mb-4`}/>
            <CardTitle>Visit Us</CardTitle>
            <CardText>Noida/Ghaziabad, UttarPradesh, India</CardText>
          </Card>
          <div css={tw`md:flex flex-col md:flex-row`}>
          <Card>
            <PhoneInTalkRounded css={tw`w-64 h-64 mb-4`} />
            <CardTitle>Call Us</CardTitle>
            <CardText>+1 917-730-3770</CardText>
          </Card>
          <Card css={tw`md:ml-24`}>
            <MailRounded css={tw`w-64 h-64 mb-4`}/>
            <CardTitle>Mail Us</CardTitle>
            <CardText>inquiry@4sonlineservices.com</CardText>
          </Card>
          </div>
          </div>
            </Image>
    </Container>
  );
};
