import React from "react";
import tw from "twin.macro";
import styled from "styled-components"; 
import HeroBackground from "../images/green_background2.jpg";
import Footer from "components/Footer.js";
import ContactUs from "components/ContactUsDetailsCardsContainer.js";
import ContactUsForm from "components/ContactUsForm.js";
import StyledHeader from "components/StyledHeader";
import FAQS from 'components/FAQs.js';

const Address = tw.span`leading-relaxed`;
const MainContainer = tw.div`pl-4 md:pl-0 pr-4 md:pr-0`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

const Heading = tw.span`text-center text-white font-medium text-5xl lg:text-7xl`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Container = styled.div`
  ${tw`h-96 bg-cover flex justify-center items-center`}
  background-image:url(${HeroBackground})
`;
export default () => {
  return (
    <>
     <StyledHeader/>  
      <Container>  
        <Heading>Ask Us</Heading> 
      </Container>
      <MainContainer>
      <ContactUs/>
      <ContactUsForm/>
      <FAQS/>
        {/* <GetStarted/>   */} 
        </MainContainer>
      <Footer />
   
      </>
  );
};
