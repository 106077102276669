import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import GetStarted from "components/CTASection.js";
import MainFeature1 from "components/FeatureDescriptionWithImageContainer.js";
import Footer from "components/Footer.js";
import Cards from '../components/ServicesCards'
import StyledHeader from "components/StyledHeader";
import HeroBackground from "../images/green_background2.jpg";

const Heading = tw.span`text-center text-white font-medium text-5xl lg:text-7xl`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const MainContainer = tw.div`pl-8 md:pl-0 pr-8 md:pr-0`;
const Container = styled.div`
  ${tw`h-96 bg-cover flex justify-center items-center`}
  background-image:url(${HeroBackground})
`;

export default () => {
  return (
    <>
      <StyledHeader/>
      <Container>  
        <Heading>Services</Heading> 
      </Container>
      <MainContainer>
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="Remote Employees and 360° Services in all Domains."
        description="We at 4 S Online Services are committed to serve globally and provide world-class services to our clients. We aim to optimize and give you intelligent, cost-effective, top-quality, and satisfactory remote employee services and solutions.
        <br/>We are entirely flexible, and that is why we can serve you better. We want to listen to you, analyze your requirement, and then we will provide you a smart, sincere and simplified solution. And we never force ourselves on our clients. If we cannot fulfill your requirement, then we will be happy to guide you, and you are most welcome to connect with us anytime in the future."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://www.newtechnorthwest.com/wp-content/uploads/2017/06/A-tried-and-true-guide-to-managing-remote-employees-640x302.png"
        textOnLeft={false}
      />
      </MainContainer>
      <Cards/>
      <GetStarted/>
      <Footer/>
      </>
  );
};
