import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
//import Hero from "components/hero/TwoColumnWithInput.js";
import Hero from "components/HeroContainer.js";
import Features from "components/FeaturesWithIconCards.js";
import MainFeature from "components/FeatureDescriptionContainer.js";
import SliderCard from "components/ServicesOfferedCardsSlider.js";
import GetStarted from "components/CTASection.js";
import Blog from "components/SpecialFeaturesCards.js";
import Testimonial from "components/Testimonials.js";
import Footer from "components/Footer.js";
import Logos from 'components/ComparisonCards.js';
import styled from "styled-components";
import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "components/misc/CommonHeader.js";
import StyledHeader from "components/StyledHeader";


export default () => {
  const MainContainer = tw.div`pl-4 md:pl-0 pr-4 md:pr-0`;
  const HighlightedText1 = tw.span`bg-green-100 text-headings-2 px-4 transform -skew-x-12 inline-block`;
  const Subheading = tw.span`uppercase tracking-widest font-bold text-headings-2`;
  const HighlightedText = tw.span`text-headings-2`;
  const heading1 = "Smart. Sincere."
  const heading2 = "Simplified Solutions"
  const notification="We at 4 S ONLINE SERVICES are committed to serve globally and provide world-class remote employee services to our clients. We aim to optimize and give you intelligent, cost-effective, top-quality, and satisfactory services and solutions."
  const features = [
    `Part-time/Full-time Employees` ,
    `Pay as you go`, `Availability in all timezones`,
    `Hire across any domain`,
    "Qualified professionals",
    "Free Demo Available"
  ];

  return(
  
  <>
    <StyledHeader/>
    <Hero heading1={heading1} heading2={heading2} notification={notification} features={features} homePageCss={tw`pb-20`}/>
       <Features />  
    <MainFeature />
    <MainContainer>
    <SliderCard/>
      <Blog/>
      <Logos/>
    <Testimonial 
        heading={<>Our Clients <HighlightedText>Love Us</HighlightedText></>}
      />
       </MainContainer>
    <GetStarted/>   
    <Footer />
</>
)};
