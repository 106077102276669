import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../images/svg-decorator-blob-3.svg";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CrossImage from '../images/cross.png';
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

const Container = tw.div`mt-4 pl-4 md:pl-0 pr-4 md:pr-0 relative`;
const Content = tw.div`max-w-screen-xl mx-auto`;
const ThreeColumn = tw.div`flex flex-col items-center justify-center lg:flex-row flex-wrap`;
const Column = tw.div`mt-16 lg:w-1/2`;

const HeadingInfoContainer = tw.div`mt-16 md:mt-8 md:mb-4 flex flex-col items-center`;
const HeadingDescription = tw.p`font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`mt-4 md:mt-12 relative lg:mx-4 xl:mx-8 max-w-sm pb-2 md:pb-8 pt-2 md:pt-4 flex flex-col w-full rounded-4xl shadow-2xl hocus:shadow-raised mb-6 min-h-108 md:min-h-144`;
const Image = styled.div(props => [
  `background-image: url("${CrossImage}");`,
  tw`bg-cover h-56 lg:h-56 rounded transform rotate-45`
]);

const Details = tw.div`p-6 rounded-lg border-2 border-orange-200 shadow-2xl flex-1 flex flex-row items-center text-center block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`ml-6 mr-6 leading-snug font-bold text-3xl mb-0 md:mb-6`;
const Description = tw.p`mt-2 text-base text-black`;
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`
const FeatureList = tw.ul`mb-6 leading-loose flex flex-col max-w-xl mx-0`;
const Feature = tw.li`mr-2 ml-6 mt-2 items-center flex flex-shrink-0 w-11/12 justify-start flex-row`;
const Feature1Icon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureIcon = tw(CheckCircleIcon)`w-5 h-5 text-primary-500`;
const Feature2Icon = tw(CancelIcon)`w-5 h-5 text-red-600`;
const FeatureText = tw.p`ml-2 pt-0 font-medium text-base md:text-lg text-black`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({
  subheading = "",
  heading = <>What we do that <span tw="text-headings-3">others don’t!</span></>,
  description = "",

}) => {
  const features = [
    'Monthly, Quarterly, Half yearly & Annual subscriptions',
    'Easy to Start & easier to Stop services',
    'Temp or Permanent – Part-time or Full time employees',
    'Complete control and everyday monitoring',
    'No hidden charges and overhead costs',
    'No minimum hiring constraints – Hire one emp. Or build teams as you like',
    'Cost efficient and time saving services for small scall companies and startups',
    'With us, your business stays online 24x7 365 days']
  //   [`Pay as you go`,
  //   `Inspections Services across every industry in India`,
  //   `Easy to Start and even easier to Stop`,
  //   "Temp or Permanent, Part-time or Full-time",
  //   "Complete control and everyday monitoring"],
  //   [`Annual subscriptions`,
  //   `Hidden and overhead costs`,
  //   `Binding contracts or recurring payment subscriptions`,
  //   "Minimum hiring constraint"],
  // ];
  const blogPosts = [
    {
      title: ""
    },
    // {
    //   title: "What We Do"
    // },
    // {
    //   title: "What We Don't"
    // }
  ];
  return (
    <Container id="weDealAndNotDelay">
      <Content>
        <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          {/* <HeadingDescription>{description}</HeadingDescription> */}
        </HeadingInfoContainer>
        {/* <ThreeColumn>
          {blogPosts.map((post, index) => (
            
              <Card key={index} css={index==0?tw`bg-gray-1000`:tw`bg-primary-1400`}>      
             
                 {index==0? <Feature1Icon css={tw`w-24 md:w-32 h-24 md:h-32 text-primary-1000 self-end`}/>:
            
              <Image css={tw`opacity-15 w-24 md:w-32 h-24 md:h-32 self-end`}/>}
                <Title css={index==0?tw`text-white`:tw`text-black`}>{post.title}</Title>
               <FeatureList>
                {features[index].map((feature, index1) => (
                  <Feature key={index1}>
                    {index==0?<Feature1Icon/>:<Feature2Icon/>}
                    <FeatureText css={index==0?tw`text-white`:tw`text-black`}>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
              </Card>
            
          ))}
            </ThreeColumn> */}
           <ThreeColumn > 
              {/* <Card css={tw`bg-primary-1400`}>                 */}
                {/* <Feature1Icon css={tw`w-24 md:w-full h-24 md:h-32 text-primary-1000 self-end`}/>
                <Title css={tw`text-black`}>Features We Offer</Title> */}
               <FeatureList>
               <Timeline position="alternate">
                {features.map((feature,index) => (
        <><TimelineItem>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="success" />
            {index!==features.length-1?<TimelineConnector />:null}
          </TimelineSeparator>
          <TimelineContent sx={{color:'#183b25'}}>{feature}</TimelineContent>
        </TimelineItem>
        
        </>
      ))}
    
    </Timeline>
              </FeatureList>
              {/* </Card> */}
        </ThreeColumn>
      
      </Content>
      {/* <DecoratorBlob1 /> */}
      {/* <DecoratorBlob2 /> */}
    </Container>
  );
};