import React, { useState,useEffect } from "react";
import { motion } from "framer-motion";
import 'react-sticky-header/styles.css';
import StickyHeader from 'react-sticky-header';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/logo512.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";


const Header = tw.header`fixed z-20 w-full`;

export const NavLinks = tw.div`inline-block`;

export const NavLink = tw.a`
  text-lg my-2 lg:text-lg lg:mx-6 lg:my-0 text-green-200 md:text-white
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-white hocus:text-black md:hocus:text-white
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded text-white
  hocus:bg-primary-500 hocus:text-white focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center md:text-center font-black text-white border-b-0 text-xl md:text-3xl! ml-0! mr-12`};
  
  img {
    ${tw`w-16 md:w-24 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between pr-8 pl-8 bg-black min-h-16`;
export const ScrolledMobileNavLinksContainer = styled(MobileNavLinksContainer)`${tw`bg-black`}`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none text-white hocus:text-green-200 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden fixed top-0 z-10 inset-x-0 mx-4 p-8 border text-center rounded-lg bg-white text-green-200`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);
export const DesktopNavLinks = tw.nav`hidden lg:flex flex-1 min-h-20 justify-between items-center pr-24 pl-24 bg-black`;
export const ScrolledDesktopNavLinks = styled(DesktopNavLinks)`${tw`bg-black`}`;
 

export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg",children}) => {

  const [sticky, setSticky] = useState("");
  
  // useEffect(() => {
  //   window.addEventListener("scroll", isSticky);
  //   return () => {
  //     window.removeEventListener("scroll", isSticky);
  //   };
  // }, []);

  const isSticky = () => {
    const stickyClass = window.scrollY > document.getElementById("header1").offsetHeight ? "is-sticky" : "";
    setSticky(stickyClass);
  };

  const defaultLinks = [
    <NavLinks key={1}>
      <NavLink href="/#">About</NavLink>
      <NavLink href="/#">Blog</NavLink>
      <NavLink href="/#">Pricing</NavLink>
      <NavLink href="/#">Contact Us</NavLink>
      <NavLink href="/#" tw="lg:ml-12!">
        Login
      </NavLink>
      <PrimaryLink css={roundedHeaderButton && tw`rounded-full`}href="/#">Sign Up</PrimaryLink>
    </NavLinks>
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="logo" tw="w-8"/>
      4 S ONLINE SERVICES
    </LogoLink>
  );
  const mobileLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="logo" tw="w-12"/>
      ONLINE SERVICES
    </LogoLink>
  );


  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;
  return(<Header><DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
    {logoLink}
    {links}
  </DesktopNavLinks>
  <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
    {mobileLogoLink}
    <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
      {links}
    </MobileNavLinks>
    <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
      {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
    </NavToggle>
  </MobileNavLinksContainer>
  </Header>)
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};
