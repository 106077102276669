import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import DesignIllustration from "../images/design-illustration-2.svg";
import HeroBackground from "../images/green_background.jpeg";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "components/misc/CommonHeader.js";
import MultilevelDropdown from "components/DropDowns/multilevelDropdown";
import { useHistory } from "react-router-dom";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative bg-center bg-cover`}
  background-image:url(${HeroBackground})
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 opacity-25`;

const HeroContainer = tw.div`relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;
const IllustrationContainer = tw.div` hidden md:flex justify-center lg:justify-end items-center w-144`;
const FeatureList = tw.ul`mt-6 leading-loose flex flex-wrap max-w-xl mx-auto lg:mx-0`;
const Feature = tw.li`mt-2 flex items-center flex-shrink-0 w-full sm:w-1/2 px-2 justify-start md:justify-center lg:justify-start`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-white text-gradient1-from text-gradient4-to`;
const FeatureText = tw.p`ml-2 font-medium text-white text-justify`;

const Heading = styled.h1`
  ${tw`text-2xl text-center lg:text-left sm:text-3xl lg:text-4xl xl:text-4xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2 mb-4`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block text-justify my-8 md:my-4 pl-3 text-gray-100 border-l-4 border-blue-500 font-medium`;

const PrimaryAction = tw.button`lg:mr-16 text-sm sm:text-base mt-8 px-8 py-4 bg-gradient-to-l from-gradientblack-from to-gradientblack-to text-white font-bold rounded-full shadow transition duration-300 focus:shadow-outline cursor-pointer z-10 `;
const SecondaryAction = tw.button`border-2 border-white px-2 py-2  lg:mr-16 text-sm sm:text-base sm:mt-8 sm:px-8 sm:py-4 bg-transparent text-white font-bold rounded-full shadow  transition  duration-300 hocus:text-white cursor-pointer hocus:text-green-500 hocus:border-green-500`;

// const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
//   padding-bottom: 50% !important;
//   padding-top: 0px !important;
//   ${tw`rounded`}
//   iframe {
//     ${tw`rounded bg-black shadow-xl`}
//   }
// `;

export default ({heading1,heading2,notification,features,homePageCss=tw``}) => {
  const history = useHistory();
  const menu = 
  {
    name: "IT and Software",
    link: "#",
    type: "page",
    children: [
      {
        name: "Website Desigining",
        link: "#",
        type: "page",
        children: [
          {
            name: "Website Development",
            link: "#",
            type: "page",
            children: [
              {
                name: "SEOs",
                link: "#",
                type: "page"
              },
              {
                name: "Link Building",
                link: "#",
                type: "page"
              }
            ]
          },
          {
            name: "Amazon Web Services",
            link: "#",
            type: "page",
            children: [
              {
                name: "Fargate Services",
                link: "#",
                type: "page"
              }
            ]
          }
        ]
      },
      {
        name: "Legal and Accounting",
        link: "#",
        type: "page",
        children: [
          {
            name: "Business Related Services",
            link: "#",
            type: "page",
            children:[
              {
                name: "Data Entry",
                link: "#",
                type: "page"
              },
              {
                name: "Data Entry",
                link: "#",
                type: "page",
                children:[ {
                  name: "Data Entry",
                  link: "#",
                  type: "page"
                },
                {
                  name: "Data Entry",
                  link: "#",
                  type: "page"
                }]
              }
            ]
          },
          {
            name: "Data Entry",
            link: "#",
            type: "page"
          }
        ]
      }
    ]
  };

  
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Home</NavLink>
      <NavLink href="/AboutUs">About Us</NavLink>
      <NavLink href="/Services">
        Services
        {/* <MultilevelDropdown heading={'Services'} menu={menu}/> */}
      </NavLink>    
      <NavLink href="/AskUs">Ask Us</NavLink>
    </NavLinks>,
  ];

  return (
    <>
    {/* <StyledHeader links={navLinks}/> */}
    <Container>
        <HeroContainer>
          <TwoColumn css={homePageCss}>
            <LeftColumn>
              <Heading>
                <span>{heading1}</span>
                <br/>
                {heading2}
              </Heading>
              {notification?<Notification>
               {notification}
              </Notification>:null}
              {features && <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>}
              <PrimaryAction onClick={()=>window.location.href='/AskUs#contactUsForm'}>Get Started</PrimaryAction>
            </LeftColumn>
            <RightColumn>
              <IllustrationContainer>
                <img
                  tw="min-w-0 w-full max-w-lg xl:max-w-4xl"
                  src={DesignIllustration}
                  alt="Design Illustration"
                />
              </IllustrationContainer>
            </RightColumn>
          </TwoColumn>
       
      </HeroContainer>
    </Container>
    </>
  );
};
