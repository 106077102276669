import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import ServicesBackground from 'images/services_background.png'
 import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
  
const Container = tw.div`relative bg-green-25`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-row items-center md:items-stretch flex-wrap justify-center max-w-screen-lg mx-auto py-8`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full text-3xl sm:text-4xl lg:text-4xl `;
const Description = tw(SectionDescription)`w-full text-headings-2`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`w-full md:w-1/3 max-w-sm mb-4`}
`;

const Card = styled.div`
  ${tw`flex flex-col bg-white sm:flex-row items-center text-center h-full mx-8 md:mx-4 px-2 py-8 border shadow-raised rounded-3xl min-h-56 md:min-h-72`}

  .textContainer {
    ${tw`mt-4 sm:mt-2 w-full px-8`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-xl md:text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-headings-3 leading-6`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Our Services", subheading = "", description = "We offer a variety of services at unbeatable costs. Although, we firmly believe that cost is not always something which needs to be considered when it comes to quality, and that is where you will find us with perfect balance satisfying your requirements to 100%." }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) title - the title of the card
   *  2) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = {
    "Data Entry & Admin":[
      {
        title:'Personal Assistant',
        description:'Now schedule appointments, manage calendars, generate leads, manage your inbox and other recurring tasks easily.'
      },
      {
        title:'Data Processing',
        // description:'Now schedule appointments, manage calendars, generate leads, manage your inbox and other recurring tasks easily.'
      },
      {
        title:'Operations Manager',
        // description:'Our QA testers are qualified in some of the best testing tools such as Testim, Autify, Selenium to test your projects and ensure faster releases.'
      },
      {
        title:'ERP & SAP Consultant',
        // description:'Our professional are expert in brand building through Digital marketing.'
      },
    ],
    "Development":[
    {
      title: "Mobile App Development",
      description: "Bespoke Mobile app development for Android, IOS and Windows by highly trained professionals.."
    },
    {
      title:'Software Engineer/ QA Engineers',
      description:'Our QA testers are qualified in some of the best testing tools such as Testim, Autify, Selenium to test your projects and ensure faster releases.'
    },
    { 
      title: "Graphics and Web Designing",
      description: "We are a hub of web and graphics innovation, our professionals are creative experts in all advanced development technology."
    },
    { 
      title: "Website Development",
      description: "We provide Profressional websites with all domains. "
    },
    {
      title: "SEO",
      description: "Looking for higher website ranking choose us.."
    }],
    "Sales & Marketing":[
    { 
      title: "Brand Management"
    },
    { 
      title: "Digital Marketing"
    },
    { 
      title: "E-Commerce",
      description: "Shopify & WordPress Professionals"
    },
    {
      title: "Online Marketplace Manager",
      description: "Amazon, Walmart and others"
    },
    { 
      title: "Google Ads Manager",
      description: "Adword, Adsense and Advertising Console"
    },
    { 
      title: "Social-Media Marketing",
      description: "Instagram Facebook and LinkedIn"
    },
    { 
      title: "Lead Generation",
      description: "Email and Tele Marketing"
    }
  ],
  "Customer service & Accounting":[
    { 
      title: "Accounting & Payroll",
    },
    {
      title: "Book keeping",
    },
    { 
      title: "Email & Phone support",
    },
    { 
      title: "Customer Relationship Manager",
    },
    { 
      title: "Live chat support",
    }
  ],

  "Other Services":[
    { 
      title: "Content Writing",
      description: " Academic Writing, Article Rewriting, Article Writing, Blog, Communications, Content Writing, Copy Editing, Copy Typing, Copywriting, Creative Writing."
    }, 
    {
      title: "Design, Media & Architecture",
      description: " 2D Animation, 3D Animation, 3ds Max, Animation, App Designer, AutoCAD Architecture, Banner Design, Blog Design, Brochure Design, Business Card Design, Corel Painter"
    },{ 
      title: "Accounting",
      description: " A/R analysis, A/R Collections, A/R Management, Account Management, Account Payables Management, Account Receivables Management, Accounting, Bank Reconciliation, Inventory Management, Intuit QuickBooks"
    },{ 
      title: "Legal",
      description: " Compliance, Compliance and Safety Training, Contracts, Corporate Income Tax, Corporate Transactions, Custom Duties Tax, Legal, Legal Research, Legal Writing, Tax"
    },{ 
      title: "Freight, Shipping & Transportation Consultation",
      description: " Cargo Freight, Freight, Import/Export, Logistics, Freight Forwarding"
    }]
  };

  if (!cards) cards = defaultCards;
  const [value, setValue] = useState('Data Entry & Admin');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container>
      {/* <ThreeColumnContainer>
        
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer">            
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer> */}
       <ThreeColumnContainer><VerticalSpacer />
       <Heading>{heading}</Heading>
       {description && <Description>{description}</Description>}
       <div css={tw`w-full my-4`}>
      <Tabs
      variant={window.innerWidth<=768?"scrollable":""}
      scrollButtons={window.innerWidth<=768?true:false}
      wrapped ={window.innerWidth<=768?true:false}
      centered ={window.innerWidth>768?true:false}
      allowScrollButtonsMobile={window.innerWidth<=768?true:false}
      TabIndicatorProps={{
        sx: {
          backgroundColor: '#183b25'
        },
      }}
        onChange={handleChange} value={value}>
          {Object.keys(defaultCards).map((serviceType)=><Tab sx={{"color":'#183b25'}} key={serviceType} label={serviceType} value={serviceType}/>)}</Tabs>
          </div>
         {Object.entries(defaultCards).map(([serviceType,serviceOptions])=>{
             return (<>{serviceOptions.map((card, i) => {
             return serviceType===value?(<Column key={i} index={i}>
                <Card value={serviceType}>
                  <span className="textContainer">            
                    <span className="title">{card.title || "Fully Secure"}</span>
                    <p className="description">
                      {card.description || card.title }
                    </p>
                  </span>
                </Card>
              </Column>):null})}</>)         
          })}
          </ThreeColumnContainer>
      {/* <DecoratorBlob /> */}
    </Container>
  );
};