import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(Container)`px-4 text-headings-4`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-headings-4 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-black`;
const ContentWithPaddingXl1 = tw.div`px-0 md:px-24 py-8 md:py-16`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-green-200 hover:border-green-200 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 text-headings-2`;

export default ({
  subheading = "",
  heading = "Frequently Asked Questions",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  faqs = [
    {
      question: "Are there any hidden or additional costs in using your Online Services and hiring an employee, and is there a commitment/contract for a minimum period?",
      answer:
        "No. There are no hidden costs. Everything will be crystal clear and fixed from the start as soon as you decide to opt for our services and choose a suitable plan as per your needs from the various plans available. Also, there are no commitments to bind you to us if you wish to leave. Although We will present a Service Agreement to you before starting any service and as soon as you agree to all the terms and conditions, we are good to go. Most of the Plans are monthly, and once you choose to stop using our services, you may unsubscribe anytime."
    },
    {
      question: "Do you train the employees which you will be hiring for us?",
      answer:
        "We don't directly train our employees unless required, as most of them come from professional backgrounds and have the requisite skills to perform your tasks. In most cases, a new resource will be assigned to you to cater to your needs precisely, and we will already train as per your requirement."
    },
    {
      question: "Does my resource work on weekends/holidays, and do you provide backup when my resource is away?",
      answer:
        "The resource provided to you will work as per your requirement. We follow the work culture in which we give two days off to all our employees, which are usually the weekends. However, you have complete control in deciding the working days and the non-working days for your resource. It will be great to provide prior notice if you want to change the working days or the timings. And yes! Our employees can take the leaves in case of emergencies related to health and for personal reasons. But we will never leave you stranded and will be there for you with the alternatives."
    },
    {
      question: "Are your plans flexible as I am not comfortable with available ones?",
      answer:
        "We have researched deeply keeping in mind the current market trends and spent numerous hours designing all our plans so that you should be able to choose one as per your need and start with it. You are also eligible to downgrade, upgrade, or cancel your subscription at any point in time. But as we know, not every customer is the same, so YES, we can tailor-make a plan for you if you want to give us a chance even though you do not wish to choose from any existing plan available. You need to contact us, and we will be there for you with a plan of your liking."
    },
    {
      question: "How do you ensure the efficiency of the employee?",
      answer:
        "When a resource is assigned to you as per your requirement, we will be adopting various performance monitoring methods available. You will get comprehensive reports regularly with KPI's to show your employee's performance. Not all employees are the same and so are not all the employers and their requirements, and that is why it will depend on various factors like Nature of Work, Place of Work, Reporting Managers if available, etc. Rest assured, All your tasks are strictly monitored and will be delegated to the employee within the time frame to complete them. Also, We interview all our employees. The work process is thoroughly explained to them, so they are trained to work as per your need with utmost dedication and efficiency even if no one is hovering around."
    },
    {
      question: "What sets 4 S ONLINE SERVICES apart from the others?",
      answer:
        "We have a different Vision &amp; Mission, and that is what sets us apart from the competition. We are in the market to serve the client's needs regardless of how small they are. We are very much interested in serving you even if you require just a single employee for part/full-time services, and We are capable enough to serve a medium or large scale project by hiring a complete team for them. We first understand the nature of work, and after analyzing, we will provide you a free demo of the services you require, and then you can decide if you want to hire someone or want to use our services or not. We will be happy to serve even if you are a small startup or a store that needs a part-time accountant, or only a business who needs someone to pick up their calls. We are committed to reducing your costs and can save you plenty of time and money in hiring an employee."
    },
    {
      question: "Is there any Data Security?",
      answer:
      "We provide 100% assurance that your data is completely secured with us. The systems and servers in use are completely encrypted and are monitored 24/7 to keep everything safe. All our employees are hired with a pre-requisite to signing a Non-disclosure agreement (NDA). They cannot share any data, including passwords, financial information, and other vital information to anybody unless required by you."
    },
    {
      question: "Do you provide the necessary equipment like Computers etc. to the employees and Are they trained enough to absorb in the US/UK/Australia work culture?",
      answer:
        "Yes. We already have all the necessary hardware and software systems in place for all our employees to serve you. Rest depends upon your requirement and the plan you chose, which will enable your resource to work with any special equipment if required. Employees are hired as per your requirement and their capability to easily cope-up in any work culture. All our employees speak English, and we also provide accent training as per the requirement to blend seamlessly."
    },
    {
      question: "Can I add more than one user to my account?",
      answer:
      "Yes. You can have more than one user who can assign the tasks on your behalf and manage your work for you. You can authorize anyone from your work, family, or friends. Just let us know their details before handing over your account to them. Although you are not allowed to share your subscription with others, it is vital for your business not to share the subscription as it can hamper your data security and lead to data leakage, loss of trade secrets, finance and account information, and essential techniques that you apply in your system. Even if someone is interested in our services and asks you to share your subscription, please do not share and ask them to get their own. You can refer us and earn great value as discounts in your subscription and even cash rewards too."
    }
  ]
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 }
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: { opacity: 1, height: "auto", marginTop: "16px", display: "block" },
            collapsed: { opacity: 0, height: 0, marginTop: "0px", display: "none" }
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl1>
        <HeadingContainer>
          {/* {subheading && <Subheading>{subheading}</Subheading>} */}
          <Heading>{heading}</Heading>
          {/* <Description>{description}</Description> */}
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingXl1>
    </PrimaryBackgroundContainer>
  );
};
