import "slick-carousel/slick/slick.css";
import React, { useEffect,useRef, useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import loveIllustrationImageSrc from "images/world_map1.png";
import { ReactComponent as StarIconBase } from "images/star-icon.svg";
import Video1 from 'videos/4S-1.mp4';
import Video2 from 'videos/4S-2.mp4';
import Video3 from 'videos/4S-3-Test.mp4';
import ResponsiveVideoEmbed from "helpers/ResponsiveVideoEmbed";


const Row = tw.div`flex flex-col md:flex-row justify-between items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 xl:w-5/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 xl:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:pr-12 lg:pr-16 md:order-first` : tw`md:pl-12 lg:pl-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);

const Subheading = tw(SubheadingBase)`text-center`;
const Heading = tw(
  SectionHeading
)`mt-4 mb-4 font-black text-3xl sm:text-4xl lg:text-4xl text-center`;
const Description = tw.p`mt-6 text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-headings-4`;

const TestimonialSlider = styled(Slider)`
  ${tw`w-full mt-2 md:mt-10 text-center md:text-left`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const TestimonialsContainer=tw.div`flex flex-col md:flex-row justify-center  mx-auto max-w-screen-xl`;
const Testimonial = tw.div`outline-none h-full flex! flex-col`;
const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
`;
const StarsContainer = styled.div``;
const StarIcon = tw(StarIconBase)`inline-block w-5 h-5 text-orange-400 fill-current mr-1 last:mr-0`;
const TestimonialHeading = tw.div`mt-4 text-xl font-bold text-headings-2`;
const Quote = tw.blockquote`mt-4 mb-8 sm:mb-10 leading-relaxed font-medium text-headings-4`;

const CustomerInfoAndControlsContainer = tw.div`mt-auto flex justify-center items-center flex-col sm:flex-row`;

const CustomerInfo = tw.div`flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-16 h-16 sm:w-20 sm:h-20`;
const CustomerTextInfo = tw.div`text-center md:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-bold text-xl text-headings-3`;
const CustomerTitle = tw.p`font-medium text-headings-4`;

const Controls = styled.div`
  ${tw`flex mt-8 sm:mt-0`}
  .divider {
    ${tw`my-3 border-r`}
  }
`;
const ControlButton = styled.button`
  ${tw`mx-3 p-4 rounded-full transition duration-300 bg-green-100 hover:bg-gray-300 text-headings-1 hover:bg-primary-1600 focus:outline-none focus:shadow-outline`}
  svg {
    ${tw`w-4 h-4 stroke-3`}
  }
`;

export default ({
  imageSrc = loveIllustrationImageSrc,
  imageRounded = true,
  imageBorder = true,
  imageShadow = true,
  subheading = "Testimonials",
  heading = "Our Clients Love Us.",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam.",
  textOnLeft = false,
  
  testimonials=[{
    customerName:'Adam 1',
    customerVideo:Video1
  },
  {
    customerName:'Adam 2',
    customerVideo:Video2
  },
  {
    customerName:'Adam 3',
    customerVideo:Video3
  }]
}) => {
  const [sliderRef, setSliderRef] = useState(null);
  const [currentActiveVideo,setCurrentActiveVideo] = useState(0);

  const settings = {
    initialSlide:0,
    autoPlay:true,
    // afterChange: current => {  
    //   setCurrentActiveVideo(currentActiveVideo);   
    //    playMovie(current);
    // }
  };
  // useEffect(()=>{

  //   const elementContainer = document.getElementById('testimonialContainer');
  //   console.log("coming here",elementContainer)
  //   elementContainer.addEventListener("onMouseOver",()=>playMovie(currentActiveVideo));
  //   console.log(currentActiveVideo)
  //onMouseMove={(e)=> document.getElementById('testimonialVideo0').play()}
  // },[])

  // const playMovie = (autoPlaySlide) => {
  //   document.getElementById('testimonialVideo'+autoPlaySlide).play();
  // }
  return (
    <Container>
      <ContentWithPaddingXl id={"testimonialContainer"}>
        <Heading>{heading}</Heading>
            {/* <Subheading>{description}</Subheading> */}
            {/* <TestimonialSlider arrows={false} ref={setSliderRef} {...settings}>
              {testimonials.map((testimonial, index) => (
                <Testimonial key={index}>
                  <div css={tw`h-80 md:h-144 w-auto`}>
                  <video src={testimonial.customerVideo}  css={tw`h-80 md:h-144 w-auto`} controls id={`testimonialVideo${index}`} onEnded={sliderRef?.slickNext}></video>
                  </div>
                </Testimonial>
              ))}
            </TestimonialSlider> */}
             <TestimonialsContainer>
              {/* {testimonials.map((testimonial, index) => (     
                <div css={tw`mb-8 md:mb-0 md:mr-8 md:w-1/3 max-h-screen`}> 
                  <video src={testimonial.customerVideo} css={tw``} controls id={`testimonialVideo${index}`} preload="auto"></video>
                  </div>))} */}
                  <div css={tw`flex flex-col md:flex-row mx-auto`}>
                  <div css={tw`flex-1 md:mr-8`}>
                  <video src={testimonials[0].customerVideo} css={tw`mb-8`} controls id={'0'} preload="auto"></video>
                  <video src={testimonials[1].customerVideo} css={tw`mb-8 md:mb-0`} controls id={'1'} preload="auto"></video>
                  </div>
                  <div css={tw`flex-1`}>
                  <video src={testimonials[2].customerVideo} css={tw`h-full`} controls id={'2'} preload="auto"></video>
                  </div>
                  </div>
            </TestimonialsContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
