import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../images/svg-decorator-blob-3.svg";

const Container = tw.div`relative px-4 md:px-0`;
const Content = tw.div`max-w-screen-xl mx-auto lg:mb-16`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`relative mt-12 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`font-medium text-headings-4 text-center max-w-sm`;

const Card = tw.div`rounded-4xl lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-72 md:h-80 w-full bg-orange-100 hover:bg-orange-200 hover:shadow-2xl transition duration-500 ease-in-out transform hover:-translate-y-4 hover:scale-200`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-56 lg:h-56 rounded rounded-b-none`
]);

const Details = tw.div`rounded-4xl border-2 border-orange-200 shadow-2xl flex-1 flex flex-row items-center text-center block lg:text-left transition duration-500 ease-in-out transform hover:-translate-y-4 hover:scale-200`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-2 p-2 md:p-4 leading-snug font-bold text-lg md:text-xl text-headings-1 w-3/5`;
const Description = tw.p`mt-0 md:mt-2 p-2 md:p-4 text-sm md:text-base text-black w-3/5`;
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;
const NumberSect = tw.div`text-8.75xl md:text-9xl`;
export default ({
  subheading = "",
  heading = <>Get Started <span tw="text-headings-3">Quickly</span></>,
  description = "",

}) => {
  const [blogPosts,setBlogPosts] = useState([
    {
      imageSrc:
        "https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      author: "Adam Wathan",
      category: "SEO",
      title: "Contact Us Right Now",
      description: "You will relate to one of our specialists who will understand your requirements and will come up with highly skilled and verified talent that can serve you.",
      url: "https://reddit.com",
      active:false
    },
  
    {
      imageSrc:
        "https://images.unsplash.com/photo-1479660095429-2cf4e1360472?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      author: "Owais Khan",
      category: "Advertising",
      title: "Take Demo of our services",
      description: "If you like what we demo, All the IT infrastructure setup, process and system reviews, employee training & any needs will be taken care of by us.",
      url: "https://timerse.com",
      active:false
    },
    { 
      imageSrc:
        "https://images.unsplash.com/photo-1579869847514-7c1a19d2d2ad?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80",
      author: "Steve Schoger",
      category: "Social Media",
      title: "Get, Set, Go",
      description: "Once everything is set-up, just pay and get ready to add a new member to your workforce.",
      url: "https://timerse.com",
      active:false
    }
  ]);
  const handleCardHover = (e,activeIndex) => setBlogPosts(blogPosts.map((blog,index)=>index===activeIndex?{...blog,active:!blog.active}:{...blog}));
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          {/* {subheading && <Subheading>{subheading}</Subheading>} */}
          <HeadingTitle>{heading}</HeadingTitle>
          {/* <HeadingDescription>{description}</HeadingDescription> */}
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card  onMouseOver={(e)=>handleCardHover(e,index)} onMouseOut={(e)=>handleCardHover(e,index)}>
               <NumberSect css={post.active?tw`absolute  left-2/3 bottom-0 top-2 font-bold  text-red-400 opacity-75`: tw`absolute  left-2/3 bottom-0 top-2 font-bold text-red-400 opacity-15`}>{index+1}</NumberSect>
                <Details>         
                  {/* <MetaContainer>
                    <Meta>
                      <UserIcon />
                      <div>{post.author}</div>
                    </Meta>
                    <Meta>
                      <TagIcon />
                      <div>{post.category}</div>
                    </Meta>
                  </MetaContainer> */}
                  <Title>{post.title}</Title>
                  <Description>{post.description}</Description>
                  {/* <Link href={post.url}>Read Post</Link> */}
                </Details>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
