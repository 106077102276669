import Dropdown from 'react-multilevel-dropdown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

export default(({heading,menu})=>{
    return(<Dropdown
    openOnHover={true}
        title={heading}
       css={tw` text-lg lg:text-lg lg:mx-0 text-green-200 md:text-white
       font-semibold p-0
      border-b-2 border-transparent`}
      >
       {menu.children &&
            menu.children.map((item,index) => (
              <Dropdown.Item key={index} css={tw`text-headings-2 hocus:bg-transparent hocus:text-headings-4`}>
                {item.name}
                {item.children && <Dropdown.Submenu position="left" openOnHover={true} css={tw`mr-0 md:mr-1`}>
                  {item.children.map((submenu) => (
                      <Dropdown.Item key={submenu.name} css={tw`text-headings-2 hocus:bg-transparent hocus:text-headings-4`}>{submenu.name} 
                      {submenu.children &&
                        <Dropdown.Submenu position="left" openOnHover={true} css={tw`mr-0 md:mr-1`}>
                         {submenu.children.map((submenu1) => 
                             <Dropdown.Item key={submenu1.name}  css={tw`text-headings-2 hocus:bg-transparent hocus:text-headings-4`}>{submenu1.name}
                                  {submenu1.children &&
                        <Dropdown.Submenu position="left" openOnHover={true}  css={tw`mr-0 md:mr-1`}>
                         {submenu1.children.map((submenu2) => <Dropdown.Item key={submenu2.name} css={tw`text-headings-2 hocus:bg-transparent hocus:text-headings-4`}>{submenu2.name}</Dropdown.Item> )}
                           </Dropdown.Submenu>}
                          </Dropdown.Item>              
                         )}
                           </Dropdown.Submenu>}</Dropdown.Item> 
                  ))}
                   </Dropdown.Submenu>}
              </Dropdown.Item>
            ))}
      </Dropdown>)
});

