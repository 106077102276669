import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import GetStarted from "components/CTASection.js";
import HeroBackground from "../images/green_background2.jpg";
import Footer from "components/Footer.js";
import MainFeature1 from "components/FeatureDescriptionWithImageContainer.js";
import FeatureStats from "components/FeatureStatsContainer.js";
import Features from "components/FeaturesWithIconCards.js";
import SimplifiedIcon from "images/SimplifiedIcon.png";
import SincereIcon from "images/SincereIcon.png";
import SmartIcon from "images/SmartIcon.jpeg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import StyledHeader from "components/StyledHeader";
import LogoImage from "images/logo_detail.png";

const Heading = tw.span`text-center text-white font-medium text-5xl lg:text-7xl`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Container = styled.div`
  ${tw`h-96  bg-cover flex justify-center items-center`}
  background-image:url(${HeroBackground})
`;
const MainContainer = tw.div`pl-8 md:pl-0 pr-8 md:pr-0`;

export default () => {
  return (
    <>
      <StyledHeader/>
      <Container>  
        <Heading>About Us</Heading> 
      </Container>
      {/* <div css={tw`bg-gradient-to-r from-gradient1-from to-gradient1-to mt-32 h-96 rounded-4xl ml-24  mr-24`}>
        About Us
      </div> */}
      {/* <Hero heading1={"Our Vision"} notification={`To change the way companies grow by enabling remote collaboration between entrepreneurs and global talent.`} /> */}
      <MainContainer>
      <MainFeature1
        // subheading={<Subheading>About 4 S Online Services</Subheading>}
        heading="Smart. Sincere. Simplified Solution (4 S)"
        description="We are a team of dedicated and expert professionals who know how to deliver. We believe that there is no good in knowledge without mileage. It is just as good as months of planning without execution, which contributes to nothing. Implementation is crucial when one has to serve its purpose. We do better because we understand and analyze, plan, test, and execute in the test environment and reality.<br/><br/> Our team provides a wide range of services that we guarantee that no one can offer better. Our focus is to help and solve your problems to focus on the goals you have set up for your business."
        buttonRounded={false}
        isctarequired= {false}
        // primaryButtonText="See Portfolio"
        imageSrc={LogoImage}
      />
      </MainContainer>
      <FeatureStats/>
      <MainContainer>
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="Why should you consider outsourcing your workforce to 4S Online Services?"
        description="<ol style=list-style:auto><li>You can enjoy most of your time while just concentrating on business activities that are critical and needs your attention while our employees take care of rest of your operations.</li> <li>4S Online Services has been one of the well-known staffing companies in India, and we have been around for over 5 years now.</li> <li>We are not just a consultancy who will forward you the employee data. We provide complete staffing solutions. As per your requirement, we hire suitable employees, provide them with all the office space and setup they need to work for you and manage them 24x7 so that you don’t have to do anything apart from seeing them do your work.</li> <li>Outsourcing staffing solutions to 4S Online Services means that you can get people working for you part-time or full time and temporary or permanent without any pre-specified time.</li> </ol>"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
        isctarequired={false}
      />
      </MainContainer>
      <Features
        containerCss={tw`bg-green-25 pt-4`}
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        // description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: SmartIcon,
            title: "Smart",
            description: "Quick & intelligent solutions"
          },
          {
            imageSrc: SincereIcon,
            title: "Sincere",
            description: "Genuinely experienced & verified professionals"
          },
          {
            imageSrc: SimplifiedIcon,
            title: "Simplified Solutions ",
            description: "Fulfill your business needs through an easy setup"
          },
        ]}
        linkText=""
      />
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
        <GetStarted/>  
      <Footer />
    </>
  );
};
