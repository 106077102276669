import React, { useRef, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import MeetAndGreetSrc from 'images/meet_us.jpeg';
import emailjs from '@emailjs/browser';
import { Modal } from "@mui/material";
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { SentimentDissatisfied } from "@mui/icons-material";


const Container = tw.div`relative p-4`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-2 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-5/12 flex-shrink-0 md:h-auto`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-contain bg-no-repeat bg-center h-full w-full`,
]);
const TextContent = tw.div`lg:py-4 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-2 font-black text-left text-3xl sm:text-4xl lg:text-4xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-2 text-justify text-sm md:text-base lg:text-lg font-medium leading-relaxed text-black`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`w-full mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium text-black transition duration-300 hocus:border-green-200`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`
const ModalContainer = tw.div`bg-white flex items-center justify-between flex-col absolute top-3/10 md:top-1/3 left-1/10 md:left-1/3 w-9/12 md:w-1/3 rounded-2xl shadow-raised border-transparent`;
const ModalHeading = tw.p`p-4 md:p-8 pb-4 text-headings-2 text-3xl text-center font-bold`;
const ModalText = tw.p`p-4 md:p-8 pt-4 font-black text-2xl text-center font-bold`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "",
  heading = <>Feel free to <span tw="text-headings-3">get in touch</span><wbr/> with us.</>,
  description = "Working with Remote Employees is easier than ever before! Request a consultation by submitting your details below.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const form = useRef();
  const [open, setOpen] = useState(false);
  const [modalHeading, setModalHeading] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const sendEmail = (e) => {
    e.preventDefault();
    const isValidMail = ValidateEmail(document.getElementById('emailAddress').value)
    if(isValidMail){emailjs.sendForm('service_adnss55', 'template_vlqqzeu', form.current, 'YeiddHGfH3zgFXbqu')
      .then((result) => {
        if(result.status>=200 && result.status<=300){
          setModalHeading('Thank You')  
          setModalDescription('We will connect with you very soon!')     
          handleOpen();
          document.getElementById("contactUsFormToFill").reset();
        }       
      }).catch((error) => {
        setModalHeading('Sorry')  
        setModalDescription('The form couldn\'t be submitted. Can you try again please?')  
        handleOpen();
        document.getElementById("contactUsFormToFill").reset();
      });
    }else{
      setModalHeading('Oops!')  
      setModalDescription('Please enter a valid email!')  
      handleOpen();
    }
  };
  const ValidateEmail = (mail) =>  (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))?true:false;
  return (
    <Container>
      <TwoColumn id="contactUsForm">
        <ImageColumn>
          <Image imageSrc={MeetAndGreetSrc}/>
        </ImageColumn>
        <TextColumn>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form ref={form} id={"contactUsFormToFill"}onSubmit={sendEmail}>
              <Input type="email" name="email" id="emailAddress" placeholder="Your Email Address" required/>
              <Input type="text" name="full_name" placeholder="Full Name" required/>
              <Input type="text" name="company_name" placeholder="Company Name" required/>
              <Input type="text" name="subject" placeholder="Subject" />
              <Textarea name="message" placeholder="Your Message Here"/>
              <input css={tw`px-8 py-3 font-bold rounded bg-primary-700 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 inline-block mt-8 cursor-pointer`} type="submit" value="Send" />
            </Form>
          </TextContent>
           </TextColumn>
      </TwoColumn>
      <Modal
        open={open}
        onClose={handleClose}  
      ><ModalContainer><ModalHeading>{modalHeading}</ModalHeading>{modalHeading==='Thank You'?<SentimentVerySatisfiedIcon fontSize="large"/>:<SentimentDissatisfied fontSize="large"/>}<ModalText>{modalDescription}</ModalText></ModalContainer></Modal>
    </Container>
  );
};
