import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import StatsIllustrationSrc from "images/stats-illustration.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import ResponsiveVideoEmbed from "../helpers/ResponsiveVideoEmbed.js";
import MeetAndGreetSrc from 'images/meet_us.jpeg';
const Container = tw.div`relative bg-green-100 pt-2 pb-2`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between mx-auto max-w-screen-xl`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 self-center flex-shrink-0 relative px-8 md:px-0`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);
const TextContent = tw.div`px-8 md:px-0 lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center text-headings-4 md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-headings-1 text-3xl sm:text-4xl lg:text-4xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-justify text-headings-4 md:text-left text-sm md:text-base lg:text-base font-medium leading-relaxed`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-contain bg-no-repeat bg-center h-full w-full`,
]);
const Statistics = tw.div`flex flex-row justify-around md:flex-col items-center sm:block text-center md:text-left mt-4`;
const Statistic = tw.div`text-left sm:inline-block sm:mr-12 last:mr-0 mt-4`;
const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-secondary-500 tracking-wide`;
const Key = tw.div`font-medium text-headings-2`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block bg-primary-700 mx-auto md:mx-0`;

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);
const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
padding-bottom: 50% !important; 
padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

export default ({
  subheading = "",
  heading = (
    <>
      We have been doing this <wbr />for  <span tw="text-headings-3">more than 5 years now</span>
    </>
  ),
  description = "Remote Employees/Staffing means, 4S Online Services provides you the human resources you need in your business in a variety of domains ranging from but not limited to Customer Service, Data Admin, Operations, Digital Marketing, E-commerce management, Accounting, Web developers. The employees will be on our payroll, and they will work for you. We will take care of everything from finding the top talent you need and hiring them for you with all the documentation, to their exit process. All you need to do is let them work for you.",
  primaryButtonText = "Learn More",
  primaryButtonUrl = "/AboutUs",
  imageSrc = StatsIllustrationSrc,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = false
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const defaultStatistics = [
    {
      key: "Clients",
      value: "50+"
    },
    {
      key: "Domains",
      value: "25+"
    },
    {
      key: "Employees",
      value: "150+"
    }
  ];

  if (!statistics) statistics = defaultStatistics;
  

  return (
    <Container>
      <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
      <ImageColumn>
          <img src={MeetAndGreetSrc}></img>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics>
            <PrimaryButton as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
