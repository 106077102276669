import MultilevelDropdown from "components/DropDowns/multilevelDropdown";
import React, { useState,useRef } from "react";
import { css } from "styled-components/macro"; 
import tw from "twin.macro";
import styled from "styled-components"; 
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Header, {
    NavLink,
    NavLinks,
    PrimaryLink,
    LogoLink,
    NavToggle,
    DesktopNavLinks,
  } from "./misc/CommonHeader.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
export default () => {
    const menu = 
    {
      name: "IT and Software",
      link: "#",
      type: "page",
      children: [
        {
          name: "Website Desigining",
          link: "#",
          type: "page",
          children: [
            {
              name: "Website Development",
              link: "#",
              type: "page",
              children: [
                {
                  name: "SEOs",
                  link: "#",
                  type: "page"
                },
                {
                  name: "Link Building",
                  link: "#",
                  type: "page"
                }
              ]
            },
            {
              name: "Amazon Web Services",
              link: "#",
              type: "page",
              children: [
                {
                  name: "Fargate Services",
                  link: "#",
                  type: "page"
                }
              ]
            }
          ]
        },
        {
          name: "Legal and Accounting",
          link: "#",
          type: "page",
          children: [
            {
              name: "Business Related Services",
              link: "#",
              type: "page",
              children:[
                {
                  name: "Data Entry",
                  link: "#",
                  type: "page"
                },
                {
                  name: "Data Entry",
                  link: "#",
                  type: "page",
                  children:[ {
                    name: "Data Entry",
                    link: "#",
                    type: "page"
                  },
                  {
                    name: "Data Entry",
                    link: "#",
                    type: "page"
                  }]
                }
              ]
            },
            {
              name: "Data Entry",
              link: "#",
              type: "page"
            }
          ]
        }
      ]
    };
    const navLinks = [
        <NavLinks key={1}>
        <NavLink href="/">Home</NavLink>
        <NavLink href="/AboutUs">About Us</NavLink>
        <NavLink href="/Services">
          {/* <MultilevelDropdown heading={'Services'} menu={menu}/> */}
          Services
          </NavLink> 
        <NavLink href="/AskUs">Ask Us</NavLink>
      </NavLinks>
      ];
      return(<StyledHeader links={navLinks}></StyledHeader>);
}