import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "images/logo_detail.png";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as LinkedInIcon } from "images/linkedin-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";

const Container = tw.div`relative bg-black text-gray-100 -mb-8 px-8`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8`
const FiveColumns = tw.div`flex flex-wrap justify-between`;
const OtherColumns =tw.div`flex flex-wrap justify-between w-auto md:w-2/4`;

const Column = tw.div`w-1/2  md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-left md:text-center md:text-left`;
const CompanyColumn = tw.div`text-center md: text-left mb-16 mr-8 lg:mb-0 w-full lg:w-1/5`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoImg = tw.img`w-128`;
const LogoText = tw.h5`ml-2 text-xl font-black`;

const CompanyAddress = tw.p`max-w-xs pt-2 font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between`
const CopyrightCompanyInfoRow = tw.div`text-sm  font-normal`
const CopyrightNotice = tw.div`font-normal`
const CompanyInfo = tw.div`font-normal`

const Divider = tw.div`mt-2 mb-2  border-b-2 border-gray-800`
export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <CompanyColumn>
            <LogoContainer>
              <LogoImg src={LogoImage} />
              {/* <LogoText>4 S Online Services</LogoText> */}
            </LogoContainer>
            <CompanyAddress>
              Noida / Ghaziabad, UttarPradesh, India
            </CompanyAddress>
            <SocialLinksContainer>
              <SocialLink target="_blank" href="https://www.facebook.com/4sonlineservices">
                <FacebookIcon />
              </SocialLink>
              <SocialLink target="_blank" href="https://www.linkedin.com/company/4-s-online-services/">
                <LinkedInIcon />
              </SocialLink>
             {/* <SocialLink href="https://youtube.com">
                <YoutubeIcon />
              </SocialLink>  */}
            </SocialLinksContainer>
          </CompanyColumn>
          <OtherColumns>
          <Column>
            <ColumnHeading>Services</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/Services">Web Designing</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/Services">Data Entry & Admin</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/Services">Sales & Marketing</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/Services">BPO/KPO services</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column css={tw`pl-16 md:pl-0`}>
            <ColumnHeading>FAQ</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/AskUs">FAQ</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/AskUs#contactUsForm">Careers</Link>
              </LinkListItem>
              {/* <LinkListItem>
                <Link href="#">Privacy Policy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Terms of Service</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Disclaimer</Link>
              </LinkListItem> */}
            </LinkList>
          </Column>
          <Column >
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              <LinkListItem>
                +1 917-730-3770
              </LinkListItem>
              <LinkListItem>
                <Link href="mailto:inquiry@4sonlineservices.com">inquiry@4sonlineservices.com</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/AskUs#contactUsForm">Feedback</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/AboutUs">About Us</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          </OtherColumns>
        </FiveColumns>
        <Divider/>
        <CopyrightAndCompanyInfoRow>
          <CopyrightNotice>&copy; Copyright 2022, 4 S ONLINE SERVICES</CopyrightNotice>
          <CompanyInfo> Powered by PCfebber</CompanyInfo>
        </CopyrightAndCompanyInfoRow>
        <CopyrightCompanyInfoRow>
        Smart. Sincere. Simplified Solution.    
        </CopyrightCompanyInfoRow>
      </Content>
    </Container>
  );
};
