import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading} from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const HeadingSection = tw.div`flex-auto`;
const Heading = tw(SectionHeading)`text-center`;
const Subheading1 = tw.h3`text-justify md:text-center font-bold ml-2 mr-2 text-headings-4`;
const Subheading = tw(SubheadingBase)`text-center`;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-80 flex justify-center mb-1`}
  }
`;
const Card = tw.div`transform hover:-translate-x-20 hover:-translate-y-20 transition duration-500 ease-in-out! h-full flex! flex-col w-72! sm:rounded-tl-4xl rounded-br-5xl relative shadow-xl`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-2/3 bg-cover bg-center rounded-tl-4xl`
]);

const TextInfo = tw.div`sm:px-4 sm:py-4`;
const TitleReviewContainer = tw.div`flex flex-col ml-4`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    autoplay:true,
    slidesToShow: 4,
    autoplaySpeed:2000,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc:
        "https://cdn.mos.cms.futurecdn.net/CT9xCjqrhnPD4ivB6B8Hqe.jpg",
      title: "Web Designing, IT & Software",
      description: "",
      pricingText: "$5.99",
      rating: "5.0",
      reviews: "87",
      url: "#"
    },
    {
      imageSrc:
        "https://frontlinesmedia.in/wp-content/uploads/2021/02/20200721143858_image1.jpeg",
      title: "Data Entry & Admin",
      description: "",
      pricingText: "$2.99",
      rating: "4.8",
      reviews: "32",
      url: "#"
    },
    {
      imageSrc:
        "https://s3.amazonaws.com/utep-uploads/wp-content/uploads/unr/2020/04/14121448/UNR-MACC-2020-Q1-Skyscraper-Types-of-Accounting-Jobs-header-v2-2-1.jpg",
      title: "Accounting",
      description: "",
      pricingText: "$7.99",
      rating: "4.9",
      reviews: "89",
      url: "#"
    },
    {
      imageSrc:
        "https://salespop.net/wp-content/uploads/2016/08/SalesMarketingHelp-1.jpg",
      title: "Sales & Marketing",
      description: "",
      pricingText: "$8.99",
      rating: "4.6",
      reviews: "12",
      url: "#"
    },
    {
      imageSrc:
        "https://www.lawyer-monthly.com/Lawyer-Monthly/wp-content/uploads/2020/02/97-of-Legal-Professionals-Think-Tech-Will-Make-a-Difference-in-HR.jpg",
      title: "HR, Business & Legal",
      description: "",
      pricingText: "$7.99",
      rating: "4.2",
      reviews: "19",
      url: "#"
    },
    {
      imageSrc:
        "https://www.iccs-bpo.com/admin/images/upload_images/373318515_KPOvsBPO_ICCS-BPO.jpg",
      title: "BPO/KPO services",
      description: "",
      pricingText: "$2.99",
      rating: "5.0",
      reviews: "61",
      url: "#"
    },
    {
      imageSrc:
        "https://outreachmonks.com/wp-content/uploads/2019/03/link-building-blog-post.jpg",
      title: "Customer Support",
      description: "",
      pricingText: "$3.99",
      rating: "4.2",
      reviews: "95",
      url: "#"
    },
    {
      imageSrc:
        "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/55a27373859093.5ea2b801a2781.png",
      title: "Graphic Designing",
      description: "",
      pricingText: "$3.99",
      rating: "3.9",
      reviews: "26",
      url: "#"
    }
  ]

  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <HeadingSection>
          <Heading>Our Services</Heading>
          <Subheading1>A quick view of domains in which our remote employees can work for you.</Subheading1>
          </HeadingSection>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                  <RatingsInfo>
                    <StarIcon />
                    <Rating>{card.rating}</Rating>
                  </RatingsInfo>
                </TitleReviewContainer>
                {/* <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{card.reviews}</Text>
                  </IconWithText>
                  <IconWithText>
                    <IconContainer>
                      <PriceIcon />
                    </IconContainer>
                    <Text>{card.pricingText}</Text>
                  </IconWithText>
                </SecondaryInfoContainer> */}
                <Description>{card.description}</Description>
              </TextInfo>
              {/* <PrimaryButton>Book Now</PrimaryButton> */}
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
